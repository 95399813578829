:root {
    --primary: #FF6632;
    --secondary: #E0E1E0;
    --background: #f5f5f5;
    --input-border: #ddd;
    --input-focus: #FF6632;
}

body {
    font-family: "urw-din", sans-serif;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}


.form_wrapper {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    max-width: 700px;
    width: 505px;
    padding: 40px 30px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    font-family: "urw-din", sans-serif;
}

.form_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100%;
}

.title_container {
    text-align: center;
    width: 100%;
}

.title_container h2 {
    font-size: 24px;
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 20px;
    font-family: "urw-din", sans-serif;
}


.lessonHeader {
    font-size: 18px;
    color: var(--primary);
    font-weight: 100;
    margin-bottom: 20px;
    font-family: "urw-din", sans-serif;
}

.lessonTitle {
    font-size: 15px;
    color: var(--primary);
    margin-bottom: 20px;
    font-family: "urw-din", sans-serif;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #000;
    color: rgba(0,0,0,0.5);
}

.error-message,
.success-message {
    text-align: center;
    margin-bottom: 16px;
}

.error-message {
    color: red;
}

.success-message {
    color: green;
}

.input_field {
    position: relative;
    margin:auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    padding: 10px;
    font-family: "urw-din", sans-serif;
}

.input_field span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-family: "urw-din", sans-serif;
}

.input_field input {
    width: 100%;
    padding: 12px 10px;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    font-family: "urw-din", sans-serif;
}

.input_field input:focus {
    border-color: var(--input-focus);
    outline: none;
}
.toggle-password {
    font-family: "urw-din", sans-serif;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--input-border);
  }

.row.clearfix {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    justify-content: center;
}

.col_half {
    width: calc(50% - 8px);
}

.input_field select {
    width: 100%;
    padding: 12px 10px;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.input_field select:focus {
    border-color: var(--input-focus);
    outline: none;
}

.input_field .select_arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.async-select {
   
    width:390px;
    max-width:390px;
    font-size: 13.5px;
    margin-top: -15px;
}

.async-select .css-yk16xz-control {
    width: 100%;
    padding: 12px 10px;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    font-family: "urw-din", sans-serif;

}

.async-select .css-yk16xz-control:focus {
    border-color: var(--input-focus);
    outline: none;
    
}

.input_field .checkbox_option {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.input_field .checkbox_option input {
    margin-right: 10px;
}
.flex-justify-end{
    display:flex;
    margin:auto;
    max-width:400px;
}

.button {
    font-family: "urw-din", sans-serif;
    background-color: var(--primary);
    color: white;
    padding: 10px 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-weight: 600;
    width: 95%;
    max-width:390px;
    text-align: center;

    margin:auto;
    margin-top:16px;
}

.button:hover {
    background-color: #e05529;
}
.inputs{
    font-family: "urw-din", sans-serif;
    width:500px;
    margin:auto;
}
.password_forget{
    font-family: "urw-din", sans-serif;
    display:flex;
    margin:auto;
    color:black;
    width:70%;
    margin-top:20px;
}

.link-btn {
    font-family: "urw-din", sans-serif;
    background: none;
    border: none;
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    font-size: 1rem;
    margin-top:10px;
}

.link-btn:hover {
    text-decoration: none;
}
@media (max-width: 768px) {
    .form_wrapper{
        width:80%;
    }
}



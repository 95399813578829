.learning-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -66px 0 -61px 0;
  width: 100vw;
}

.learn-content-container {
  text-align: center;
  margin: 90px 10px 90px 10px;
  display: flex;
  align-items: center;
}

.learn-description {
  margin: 0 50px 0 50px;
  font-size: 14px;
}

.learn-description p {
  margin: 10px -32px 0 -43px;
}

.learn-description img {
  margin: 50px 0 0 0;
}

.checkpoint-ln-link {
  width: 100px;
  border: white solid 1px;
  border-radius: 10px;
  background-color: white;
  padding: 10px 0 10px 0;
}

.checkpoint-ln-link:hover {
  cursor: pointer;
  border: white solid 2px;
}

.learning-link {
  text-decoration: none;
  color: white;
}

.learning-image img {
  width: 500px;
}

@media (max-width: 449px) {
  .learn-content-container {
    flex-direction: column-reverse;
    margin: 110px 0 81px 0;
  }

  .learning-image {
    display: flex;
    justify-content: center;
  }
  .learning-image img {
    width: auto;
    height: 200px;
    margin: 0;
  }

  .learn-description img {
    margin: 41px 0 15px 0;
  }

  .learning-link {
    margin: 0 0 0 0;
  }

  .learn-description p {
    margin:  10px -30px 0 -30px;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .learn-content-container {
    flex-direction: column-reverse;
    margin: 110px 0px 81px 0px;
  }

  .learning-image {
    display: flex;
    justify-content: center;
  }
  .learning-image img {
    width: auto;
    height: 328px;
    margin: 0;
  }

  .learn-description img {
    margin: 41px 0 15px 0;
  }

  .learning-link {
    margin: 0 0 0 0;
  }

  .learn-description p {
    margin: 10px -17px 0 -1px;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .learn-content-container {
    flex-direction: column-reverse;
    margin: 110px 24px 100px -6px;
  }
  .learning-image {
    display: flex;
    justify-content: center;
  }
  .learning-image img {
    width: auto;
    height: 350px;
    margin: 0;
  }

  .learn-description img {
    margin: 41px 0 15px 0;
  }

  .learning-link {
    margin: 0;
  }

  .learn-description p {
    margin: 10px 41px 0 56px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .learn-description img {
    margin-top: 40px;
  }

  .learning-image img {
    width: auto;
    height: 350px;
    margin: 0;
  }
}

@media (min-width: 1300px){

  .learn-description{
    font-size: 18px;
  }

  .learn-description p {
    margin: 10px 0 0 0;
  }

  .learning-image img{
    height: 550px;
    width: auto;
  }

  .learn-description img{
    width: 140px;
    margin-top: 110px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
  --primary: #FF6632;
  --secondary: #E0E1E0;
  --terciary: #cac7c75c;
}

body {
  font-family: "urw-din", sans-serif;
  overflow-x: hidden;  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}


.tl-cnt {
  height: 100%;
  position: relative;
}

.tl-header {
  display: flex;
  align-items: center;
  width: 100vw;
}

.tl-title {
  text-align: center;
  margin: 20px;
}

.tl-title h1 {
  font-size: 20px;
  font-weight: bold;
  color: var(--primary);
}

.tl-title img {
  border: var(--primary) 1px solid;
  height: 150px;
  padding: 12px 0 15px 0;
  border-radius: 10px;
}

.tl-dsp p {
  padding: 20px 50px 7px 15px;
  font-size: 16px;
  text-align: justify;
}

.tl-gallery {
  display: flex;
  margin: 40px 40px 50px 40px;
  padding: 2px;
  background-color: rgb(202 199 199 / 36%);
  border-radius: 20px;
  overflow-x: auto;
}

#toolbar {
  background-color: var(--secondary);
}

.tl-image-container img {
  height: 350px;
  margin: 20px;
  transition: opacity 0.3s;
  border: var(--primary) 1px solid;
}

.tl-image-container {
  position: relative;
}

.tl-image-container.tl-hovered img {
  opacity: 0;
}

.tl-hover {
  background-color: var(--primary);
  opacity: 70%;
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 14px;
  padding: 20px;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 1;
}

.bookmark-icon,
.bookmarked-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: gray;
  cursor: pointer;
  font-size: 30px;
  z-index: 2;
  transition: transform 0.3s ease;
}

.bookmarked-icon {
  right: 10px;
  color: gold !important;
}

.tl-hovered .bookmark-icon,
.tl-hovered .bookmarked-icon {
  transform: scale(1.2);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-content h2 {
  margin-top: 0;
}

.error-popup {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: var(--primary) 1px solid;
  position: relative;
  width: 300px;
  text-align: center;
}

.error-popup p {
  font-family: "urw-din", sans-serif;
  margin-bottom: 20px;
}

.error-popup button {
  font-family: "urw-din", sans-serif;
  background: #FF4500;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.error-popup button:hover {
  background: #FF6632;
}

@media screen and (max-width: 300px) {
  .tl-image-container img {
    height: 220px;
  }
}

@media screen and (max-width: 768px) {
  body {
    display: block;
  }
  .tl-header {
    flex-direction: column;
  }
  .tl-dsp p {
    padding: 20px 20px 0px 20px;
  }
}

:root {
    --primary: #FF6632;
    --secondary: #E0E1E0;
    --background: #f5f5f5;
    --input-border: #ddd;
    --input-focus: #FF6632;
}

body {
    font-family: "urw-din", sans-serif;
    margin: 0;
    background-color: var(--background);
}


.teacher-dashboard-home-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
}


.dashboard{
    width:100%;
    background-color:black;
    margin:auto;
}

.teacher-dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 2vh;
    width: 100%;
    flex: 1;
    
}

.teacher-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--primary);
    width: 100%;
}

.teacher-content {
    padding: 1.5rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 800px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.teacher-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%;
}

.teacher-profile-pic-container {
    width: 6rem;
    height: 6rem;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.teacher-profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.teacher-info {
    text-align: center;
}

.teacher-username, .teacher-email {
    font-size: 1.135rem;
    font-weight: 400;
    color: #4A5568;
}

.teacher-change-password {
    font-family: "urw-din", sans-serif;
    color: var(--primary);
    display: block;
    margin:auto;
    margin-top: 0.5rem;
    border-color:transparent;
    background-color:transparent;
    font-size: 1rem;
}

.teacher-change-password:hover {
    text-decoration: underline;
}

.home-logo{
    width:30px;
    height:10px;
    background-color:red;
    margin-left:10px;
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.teacher-add-class-btn, .teacher-select-class-btn {
    background-color: var(--primary);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    font-family: "urw-din", sans-serif;
    margin: 0 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width:10rem;
}

.teacher-add-class-btn:hover, .teacher-select-class-btn:hover {
    background-color: #FF4500;
}

.teacher-select-container {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1.5rem;
    text-align: center;
}

.teacher-select-class {
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    font-size: 1rem;
    padding: 0.5rem;
    background-color: #f9f9f9;
    color: #333;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23666" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 1rem);
    background-position-y: 50%;
    font-family: "urw-din", sans-serif;
}

.teacher-select-class:focus {
    border-color: var(--input-focus);
    outline: none;
}

.teacher-select-class::-ms-expand {
    display: none;
}

.noBookmarks{

    font-size: 1rem;
    width:100%;
    margin:auto;
    text-align:center;
    font-family: "urw-din", sans-serif;

}

.footer {
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 100%;
    padding: 0.5rem 1rem;  
    margin-top: auto;
    font-size: 1rem;  
}

.delete-class-container{
    //background-color:green;
    
    margin-top:50px;
    display:flex;
    justify-content:center;
    margin-bottom:20px;
}

.delete-class-icon{
    font-size: 1.5rem; 
    font-family: "urw-din", sans-serif;
    color:var(--primary);
}

.delete-class-icon:hover{
    cursor:pointer;
    color:#E65C2E;
}

.bookmarks-container {
    margin-top: 2rem;
    margin-left:70px;
    overflow-y: auto;
    width: 100%;
    max-width: 850px;
    font-family: "urw-din", sans-serif;
}

.bookmarks-container h3 {
    font-size: 1.5rem;
    color: var(--primary);
    text-align: center;
    margin-bottom:2rem;
}

.bookmarks-container ul {
    list-style: none;
    padding: 0;
}

.bookmarks-container li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.bookmarked-icon-dashboard {
    margin-right: 1.5rem;
    margin-top:0.5rem;
    font-size: 1.5rem;
    color: var(--primary);
}

.bookmarked-icon-dashboard:hover {
    cursor:pointer;
    color:#E65C2E;
}

.bookmarks-container a {
    color: var(--primary);
    text-decoration: none;
}

.bookmarks-container a:hover {
    text-decoration: underline;
}

.bookmark-content {
    display: flex;
    flex-direction: column;
    margin-top:15px;
}


.bookmark-title{
    font-family: "urw-din", sans-serif;
    font-size: 1.135rem;
    font-weight: bold;
    color: #4A5568;
}
.bookmark-code{
    font-family: "urw-din", sans-serif;
    font-size: 0.8rem;
    color:#808080;
    //background-color:blue;
    font-family: "urw-din", sans-serif;
    margin-left:10px;
}

.bookmark-details{
    font-size:0.95rem;
    color: #4A5568;
    font-family: "urw-din", sans-serif;
}


.bookmark-link{
    font-family: "urw-din", sans-serif;
    margin-left:1rem;
    font-weight: 600;
    font-size:0.9rem;
}



.profile-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.profile-image {

    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    transition: opacity 0.3s;
    border-color:transparent;
}

.profile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 50%;
}

.profile-container:hover .profile-overlay {
    opacity: 1;
}

.profile-overlay img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.avatar-selection-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.close-btn {
    background: none;
    border: none;
    font-size: 24px;
    color: #999;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-btn:hover {
    color: #333;
}

.avatar-selection-wrapper h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.avatars-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.avatar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
}

.avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-item:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.dashboard2{
    display:none;   
}

@media (max-width: 768px) {
    .teacher-profile-container {
        flex-wrap: nowrap;
    }

    .teacher-add-class-btn, .teacher-select-class-btn {
        font-size: 1.125rem;
    }

    .teacher-select-container {
        max-width: 200px;
    }

    .bookmarks-container {
        margin-left:auto;
        //background-color:yellow;
    }


    .dashboard{
        //margin-top:50px;
        //width:100%;
        background-color:black;
        display:flex;
        justify-content:center;
        margin:auto;
        height:auto;
        position:static;
        
    }
    .bookmark-content p {
        //margin-left: 100px;
        //background-color:yellow;
        font-size: 1rem;
    }
    
    .bookmark-content{
        width:100%;
    }

    .bookmarked-icon-dashboard {
        margin-right: 1rem;
        margin-top:0.5rem;
        font-size: 1.5rem;
        //color: var(--primary);
    }
}

@media (min-width: 1024px) {
    .teacher-dashboard-content .bookmarks-container {
        flex-direction: column;
        align-items: center;
    }


    .teacher-content {
        width: 100%;
    }

    .bookmarks-container {
        width: 100%;
    }

    .footer {
        position: relative;
    }
}

.parent-dashboard-home-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
}

.dasboard {
  background-color: black;
}

.parent-dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 2vh;
  width: 100%;
  flex: 1;
}

.parent-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--primary);
  width: 100%;
}

.parent-content {
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin-top: -1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.parent-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
}

.profile-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transition: opacity 0.3s;
  border-color: transparent;
}

.profile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 50%;
}

.profile-container:hover .profile-overlay {
  opacity: 1;
}

.profile-overlay img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.parent-info {
  text-align: center;
}

.parent-username,
.parent-email {
  font-size: 1.135rem;
  font-weight: 400;
  color: #4A5568;
}


.parent-change-password{
  font-family: "urw-din", sans-serif;
  color: var(--primary);
  display: block;
  margin:auto;
  margin-top: 0.5rem;
  border-color:transparent;
  background-color:transparent;
  font-size: 1rem;
}

.parent-change-password:hover {
  text-decoration: underline;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.parent-add-child-btn {
  background-color: var(--primary);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: "urw-din", sans-serif;
  margin: 0 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.parent-add-child-btn:hover {
  background-color: #FF4500;
}

.children-container {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.children-container h3 {
  font-size: 1.5rem;
  color: var(--primary);
  text-align: center;
  margin-bottom: 2rem;
}

.children-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.child-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.childName{
  font-family: "urw-din", sans-serif;
  font-size: 1rem;
  margin-top:-3.7rem;
  color: var(--primary);
}
.defchildName{
  font-family: "urw-din", sans-serif;
  font-size: 1rem;
}

.child-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

.add-child-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: auto;
  font-size: 1rem;
}
.rectangular-profile-container {
  position: relative;
  width: 150px;
  height: 200px; /* Set height for rectangular box */
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.profile-container:hover .profile-overlay {
  opacity: 1;
}

.profile-overlay img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 20px;
  text-align: center;
}


body{
  background-color:white  !important;
  width:100vw;
}

.kmm-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100vw
}

.km-header {
  display: flex;
  align-items: center;
  margin: 20px 40px 20px 40px;
}

.km-header img {
  border: var(--primary) 1px solid;
  border-radius: 10px;
  height: 150px;
}

.km-text p {
  text-align: justify;
  padding: 20px 60px 7px 60px;
}

.kmm-virtual img {
  height: 40px;
}

.kmm-mag-carousel {
  background-color: var(--terciary);
  border-radius: 10px;
  margin: 20px;
  padding: 20px 40px 0px 40px;
  width: 95vw;
 
}

.kmm-mag-carousel .alice-carousel__wrapper {
  overflow-x: scroll;
}
.kmm-mag-carousel .alice-carousel__dots {
  margin-top: 10px;
  display: none;
}

.kmm-advise {
  font-size: 12px;
  text-align: center;
  margin: 0px 60px 40px 60px;
}

@media screen and (max-width: 768px) {
  .km-header {
    flex-direction: column;
  }

  .kmm-virtual {
    display: flex;
    flex-direction: column;
  }

  .km-text p {
    padding: 20px;
  }
}

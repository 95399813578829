.bs-cnt{
  display: flex;
  align-items: center;
  flex-direction: column;
  
  }
  
  .bs-title{
      margin: 20px;
      text-align: center;
      padding: 20px 100px 20px 100px;
      display: flex;
      align-items: center;
      width: 90vw;
  }
  
  .bs-title img{
      border-radius: 10px;
      height: 150px;
  }
  
  .bs-title-text{
  display: flex;
  flex-direction: column;
  }
  
  .bs-title-text p {
      margin: 20px 10px 20px 10px;
      text-align: justify;
  }
  
  .bs-row{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
  }
  
  .bs-row img{
      height: 40px;
  }
  
  .bs-podcast-cnt{
      background-image: linear-gradient(#543865, #E86641) ;
      width: 100vw;
      padding: 40px;
      display: flex;
      flex-direction: column;
  }
  
   @font-face {
      font-family: 'HVD Poster';
      font-style: normal;
      font-weight: normal;
      src: local('HVD Poster'), url('../../font/HVD_Poster.woff') format('woff');
      }
  
  .bs-podcast-title p {
      color: white;
      font-size: 18px;
      text-align: center;
      margin-bottom: 20px;
      font-family: 'HVD Poster', sans-serif;
  }
  
  .bs-podcast-actor{
      color: white;
      display: flex;
      align-items: center;
  }
  
  .bs-podcast-actor img{
      height: 100px;
      border-radius: 50%;
      border: var(--primary) 1px solid;
  }
  
  /* .bs-podcast .bs-podcast-episode{
      height: 150px;
  } */
  
  .bs-podcast {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    
    .bs-podcast-episode {
      position: relative;
      height: 150px;
      margin-right: 10px;
      margin-bottom: 120px;
      cursor: pointer;
    }
    
    .bs-player {
      position: absolute;
      bottom: 0;
      /* background-color: rgb(209, 205, 205); */
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 1;
    }
  
    @media screen and (max-width: 768px){
      .bs-title{
          flex-direction: column;
          padding: 20px;
      }
  
    
    }
  
  
.hl-main-cnt{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: start;
    width:100vw;
 }

 .hl-main-cnt h2{
    color: var(--primary);
    margin: 0 20px 10px 20px;
 }

 .hl-content-cnt{
    border: var(--primary) 1px solid;
    margin: 20px;
    padding: 20px;
 }


.hl-header{
    margin: 20px 0;
}

.hl-cnt{
display: flex;
align-items: flex-start;
justify-content: space-evenly;
}

.hl-content{
    background-color: var(--terciary);
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hl-content p {
  text-align: center;
}

.hl-btn-dwnl{
    background-color: #f3ae98;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 4px 10px 4px 10px;
    margin: 20px 0 20px 0;
    width: 150px;
}

.hl-btn-dwnl:hover{
  cursor: pointer;
  color: var(--primary);
}



.hl-pdf-description p {
  margin: 20px;
  text-align: justify;
}

.hl-index-preview{
  height: 600px;
}

.hl-lesson-preview{
  height: 600px;
}
.hl-index-preview embed{
  border: none;
  /* height: 700px;
  width: 610px; */
  width: 100%;
    height: 100%;
    padding: 0 20px 20px 20px;
  
}

.hl-lesson-preview embed{
/* height: 500px; */
/* border: rgb(164, 160, 160) 0.2px solid; */
width: 100%;
    height: 100%;
    padding: 0 0px 20px 0px;

}

.hl-lesson-btn-cnt{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

@media (max-width: 399px){
/* .hl-index-preview embed{
  width: 187px;
  height: 170px;
}

.hl-lesson-preview embed{
  width: 187px;
  height: 170px;
} */

.hl-lesson-btn-cnt{
  align-items: center;
}

.hl-pdf-description{
  margin-left: 20px;
}

.hl-content-cnt{
  padding: 0;
}

.hl-main-cnt h2{
  text-align: center;
}
}

@media (min-width: 400px) and (max-width: 549px){
/* .hl-index-preview embed{
  width: 230px;
  height: 248px;
}

.hl-lesson-preview embed{
  width: 230px;
  height: 248px;
} */
.hl-cnt{
  align-items: normal;
  }

.hl-index-preview {
  display: flex;
  justify-content: center;
}
}

@media (min-width: 550px) and (max-width: 700px){
/* .hl-index-preview embed{
  width: 370px;
  height: 340px;
}

.hl-lesson-preview embed{
  width: 370px;
  height: 340px;
} */

.hl-cnt{
  align-items: normal;
  }

.hl-index-preview {
  display: flex;
  justify-content: center;
}
}

@media (min-width: 701px) and (max-width: 990px){
.hl-cnt{
align-items: normal;
}

.hl-index-preview {
  display: flex;
  justify-content: center;
}

/* .hl-index-preview embed{
  width: 430px;
  height: 400px;
  padding: 5px 20px;
} */

/* .hl-lesson-preview embed{
  width: 100%;
  height: 300px;
} */

/* .hl-lesson-preview{
  height: 100px;
  
  
} */


}



@media (max-width: 900px){
.hl-cnt{
  flex-direction: column;

}

.hl-lesson-btn-cnt{
flex-direction: column;
}
}

@media(min-width:1200px){
.hl-lesson-preview{
  width: 570px;
}

.hl-index-preview{
  width: 570px;
}

.hl-content-cnt{
  /* border: none; */
  margin: 0;
  padding: 5px;
}
}

:root {
  --primary: #FF6632;
  --secondary: #E0E1E0;
  --tertiary: #cac7c75c;
}

.navbar-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: "urw-din", sans-serif;
  height: 70px;
  background-color: black;
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.navbar-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.navbar-item {
  color: white;
  font-family: "urw-din";
  text-decoration: none;
  padding: 7px;
  font-size: 12px;
  border-radius: 10px;
  background-color: black;
  border: none;
  margin: 0 1vw;
}

.navbar-item a {
  text-decoration: none;
  color: white;
}

.navbar-item:hover {
  background-color: var(--primary);
  cursor: pointer;
}

.person-icon {
  color: var(--primary);
  size: 50px;
}

.navbar-dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: black;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  text-align: center;
  margin-top: 3px;
}

.dropdown-content a {
  text-decoration: none;
  color: white;
  font-size: 12px;
}

.dropdown-content a:hover {
  color: var(--primary);
}

.navbar-dropdown.show .dropdown-content {
  display: block;
  overflow: hidden;
}

.navbar-dropdown.show .navbar-item {
  background-color: var(--primary);
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
}

.mobile-dropdown-menu {
  display: none;
}

.dropdown-icon {
  color: var(--primary);
  transition: transform 0.3s ease-in-out;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.checkpointLogo {
  display: none;
}

.icon-menu {
  display: none;
}

.dropdown-arrow {
  color: white;
  padding-left: 10px;
}

.nav-item-profile-dropdown-container {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  font-size: 14px;
  padding: 7px;
  position: relative;
  
}

.profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-icon {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.login-signup-icon {
  color: var(--secondary);
}

.mobile-dropdown-menu {
  z-index: 10;
}

.login-signup-popup {
  z-index: 20;
}

.nav-item-profile-dropdown-container .login-signup-btn:hover,
.nav-item-profile-dropdown-container2 .login-signup-btn:hover {
  background-color: var(--primary);
  border-radius: 10px;
}


.login-signup-btn {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: "urw-din", sans-serif;
  font-size:14px;
  align-self:center;
  padding: 8px;
  
}

.profile-dropdown-content {
  display: none;
  position: absolute;
  flex-direction: column;
  right: 0;
  background-color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  font-family: "urw-din", sans-serif;
  margin-top: 60px;
}

.profile-dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-family: "urw-din", sans-serif;
  cursor: pointer;
}

.profile-dropdown-content a:hover {
  color: var(--primary);
}

.profile-dropdown.show .profile-dropdown-content {
  display: flex;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: static;
    width: 100%;
    height: auto;
  }
  .navbar-wrapper {
    width: 100%;
  }
  .login-signup-btn{
    font-size:12px;
    margin:auto;
  }

  .logo-menu-container {
    display: flex;
    justify-content: space-between;
    width:100%;
    padding: 5px;
    //background-color: yellow;
    height:40px;
    position:static;
  }

  .checkpointLogo {
    display: block;
    //margin-left: 10px;
    //top:10px;
  }

  .icon-menu {
    display: block;
    margin-right: 10px;
   
  }

  .mobile-menu {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .navbar-item {
    padding: 10px;
    text-align: center;
  }

  .nav-social {
    display: none;
  }

  .mobile-dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 10;
  }

  .nav-item-profile-dropdown-container2{
    font-size:12px;
    position: static;
    display:block;
  }

  .icon-menu {
    display: flex;
    position: absolute;
    right: 25px;
    margin-top: 5px;
  }

  .checkpointLogo {
    display: flex;
    position: absolute;
    left: 10px;
    margin-top: -14px;
  }

  .navbar-item:hover {
    padding: 10px;
  }

  .navbar-dropdown.show .dropdown-content {
    display: flex;
    text-align: center;
    position: static;
    border-radius: 0;
    margin-top:10px;
    padding: 0 0 20px 0;
  }
  .navbar-dropdown{
    display:flex;
    flex-direction:column;
    justify-content:center;
  }

  .navbar-dropdown.show .navbar-item {
    background-color: var(--primary);
    padding: 10px;
  }

  .nav-item-profile-dropdown-container {
    display: none !important;
  }

  .profile-dropdown-content {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    //background-color:yellow;
    margin:1px;
  }

  .profile-info{
    margin-botton:40px;
    //background-color:red;
  }
}
@media screen and (max-width: 1000px) {
  .navbar-container{
    //background-color:red;
    justify-content: left;
    margin:0;
  }
  .nav-item-profile-dropdown-container{
    position:relative !important;
    right:0 !important ;
  }
}

@media (min-width: 769px) and (max-width: 800px) {
  .nav-social {
    display: none;
  }
}

@media (min-width: 1300px) {
  .navbar-item {
    font-size: 14px;
  }

}

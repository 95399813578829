.mp-cnt{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: white;
    border-radius: 10px;
    border: grey 0.5px solid;
    padding: 20px;
    max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.mp-content{
display:flex ;
flex-direction: column;
}

.mp-btn-xmark{
    border: none;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 20px;
    right: 20px;
}

.mp-btn-xmark-icon{
    color: var(--primary);
}

.mp-btn-xmark-icon:hover{
    color: rgb(236, 184, 86);
}

.mp-btn-download{
    background-color: var(--primary);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
    width: 200px;
    font-family: "urw-din", sans-serif;
}

.mp-btn-download:hover{
    cursor: pointer;
    font-weight: bold;
    background-color: rgb(255, 136, 0);
}

.mp-btn-download-cnt{
    display: flex;
    justify-content: center;
}

.mp-img-cnt{
    border: var(--primary) 1px solid;
    border-radius: 10px;
    margin: 20px;
}

.mp-img-cnt img{
    height: 400px;
}
@media (max-width:299px){
    .mp-img-cnt img{
        height: 100px;
    }

}

@media (min-width: 300px) and (max-width:600px){
    .mp-img-cnt img{
        height: 160px;
    }
}

@media (min-width:601px) and (max-width: 900px){

.mp-img-cnt img{
    height: 300px;
}
}

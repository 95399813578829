.multiverseshots-container {
  display: flex;
  justify-content: center;
  margin: 0;
}

.screenshot {
  width: 300px;
  height: auto;
  border-radius: 10px;
  margin: 0px 10px 0px 10px;
}

@media (max-width: 449px) {
  .screenshot {
    width: 230px;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .screenshot {
    width: 200px;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .screenshot {
    width: 240px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .screenshot {
    width: 200px;
  }
}

@media (min-width: 900px){
  .screenshot {
    width: 200px;
  }
}

@media (min-width: 1100px){
  .screenshot{
    width: 300px;
  }
}

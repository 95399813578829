.player{
    position: relative;
}
.pl-btn{
    position: absolute;
    bottom: 100px;
    right: 75px;
    border: none;
    background: none;
}

.pl-btn-img{
    color: white;
    
}

.pl-time{
    color: white;
    font-size: 12px;
    margin: 5px;
}
.pl-range-cnt{
    position: absolute;
    top: 70px;
    margin: 0 80px 0 -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 0 0 5px 5px;
    width: 200px;
    height: 30px;
    padding: 5px;
}

.pl-range{
   --bar-bg: white;
    --seek-before-width: 100px;
    --seek-before-color: purple;
    --knobby: rgb(56, 9, 80);
    --selectedKnobby: rgb(230, 136, 245);
    --knob-position: var(--seek-before-width);
    --knob-size: 0;
    /* --knob-offset: calc(var(--seek-before-width) - var(--knob-size) / 2); */

    appearance: none;
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    height: 6px;
    width: 100%;
    outline: none;
}

/* firefox */
.pl-range::-moz-range-track{
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 6px;
    outline: none;
}

.pl-range::-moz-focus-outer{
    border: 0;
}

/* chrome & safari */
.pl-range::before{
    content: "";
    height: 6px;
    width: var(--seek-before-width, 0);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    /* left: var(--knob-position, 0%); */
}

/* knobby chrome & firefox */
.pl-range::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: absolute;
    margin: -4px 0 0 -2px;
    z-index: 3;
    box-sizing: border-box;
    left: var(--knob-position);
    /* left: var(--knob-offset); */
    /* left: calc(var(--seek-before-width) - var(--knob-size) / 2); */
}
.pl-range[data-dragging="true"]::-webkit-slider-thumb {
    transition: none;
    /* left: calc(min(max(var(--knob-position), 0), 100) * (100% - var(--knob-size)) / 100); */
  }
/* knobby while dragging - chrome and safari */
.pl-range::-webkit-slider-thumb:active{
    /* left: calc(min(max(var(--knob-position), 0), 100) * (100% - var(--knob-size)) / 100); */
    background: var(--selectedKnobby);
}

/* knobby - firefox */
.pl-range::-moz-range-thumb{
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: absolute;
    margin: -4px 0 0 -2px;
    z-index: 3;
    box-sizing: border-box;
    left: var(--knob-position);
    /* left: var(--knob-offset); */
    /* left: calc(var(--seek-before-width) - var(--knob-size) / 2); */
}

/* knobby while draggging - firefox */
.pl-range::-moz-range-thumb:active{
    /* left: calc(min(max(var(--knob-position), 0), 100) * (100% - var(--knob-size)) / 100); */

    background: var(--selectedKnobby);
}


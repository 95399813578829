.bedtime-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 50px 10px 50px 10px;
  flex-wrap: nowrap;
  width: 100vw;
}

.bedtime-text {
  position: relative;
  text-align: center;
  margin: 34px 20px 0 20px;
  background-image: url("../images/bedtime/Beyond_Blue_Girl+Dolphin_no background.png");
  background-position-x: center;
  background-position-y: center;
  background-size: 130%;
  background-repeat: no-repeat;
  z-index: 2;
}
.child-whale {
  display: none;
  /* position: fixed;
    top: 50px; 
    right: 355px;
    z-index: -1;
    height: 300px;
    align-items: center; */
}

.bedtime-text p {
  font-size: 14px;
  margin: 10px 10px 20px 10px;
}

.bedtime-player {
  z-index: 2;
}

.bedtime-player iframe {
  width: 500px;
  height: 390px;
}

.bedtime-virtualstore-link {
  height: 48px;
  padding: 10px;
}

.bt-link {
  width: 100px;
  border: white solid 1px;
  border-radius: 10px;
  margin-top: 20px;
  z-index: 2;
}

.bt-link:hover {
  border: white solid 2px;
  cursor: pointer;
}

.virtual-row {
  margin: 40px 0 20px 0;
}

@media (max-width: 449px) {
  .bedtime-container {
    flex-direction: column;
    margin: 9px 0 45px 0;
  }
  .bedtime-player iframe {
    width: 270px;
  }

  .bedtime-text p {
    margin: 10px 15px 20px 10px;
  }

  .bedtime-text {
    font-size: 14px;
    background-position-y: 60%;
    background-position-x: center;
    background-size: 135%;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .bedtime-container {
    flex-direction: column;
    margin: 10px 0 45px 0;
  }

  .bedtime-text {
    font-size: 14px;
    background-position-y: center;
    background-position-x: center;
    background-size: 120%;
  }

  .bedtime-text p {
    margin: 10px 30px 0 30px;
  }

  .bedtime-player iframe {
    width: 400px;
  }

  .bt-link {
    margin: 20px 0px 40px;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .bedtime-container {
    flex-direction: column;
    margin: 10px 0 45px 0;
  }
  .bedtime-player iframe {
    width: 550px;
  }

  .bedtime-text {
    font-size: 14px;
    background-position-y: top;
    background-position-x: center;
    background-size: 120%;
  }

  .bt-link {
    margin: 20px 10px 20px 0;
  }

  .virtual-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0px 30px 30px 30px;
  }

  .bedtime-text p {
    padding: 0 44px 0 50px;
    margin: 24px 24px 0 24px;
  }

  .bedtime-virtualstore-link {
    height: 28px;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .bedtime-text p {
    margin: 10px 75px 10px 70px;
  }

  .bedtime-text {
    background-position-y: center;
    background-position-x: center;
  }

  .bedtime-player iframe {
    width: 320px;
  }
}

@media (min-width: 1020px) and (max-width: 1229px) {
  .bedtime-text {
    background-size: 110%;
  }

  .bedtime-text p {
    margin: 10px 87px 20px 80px;
  }
}

@media (min-width: 1230px) {
  .bedtime-text {
    font-size: 18px;
    background-size: 130%;
    background-position-y: -66px;
  }

  .bedtime-text p {
    font-size: 18px;
    margin: 10px 139px 20px 134px;
  }
}

@media (min-width: 1350px) {
  .bedtime-player iframe {
    width: 583px;
    margin-right: 44px;
  }
}

@media (min-width: 1537px) {
  .bedtime-text {
    background-size: 100%;
    background-position-y: center;
  }
}

@media (min-width: 1920px) {
  .bedtime-text {
    background-size: 50%;
    background-position-y: center;
  }
}

.kidsmag-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 19px 0px 24px 0px;
  width: 100vw;
}

.kidsmag-text {
  text-align: center;
  margin: 54px 98px 50px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kidsmag-text p {
  margin: 10px 66px 55px 66px;
  font-size: 14px;
}

.kidsmag-img {
  width: auto;
  height: 400px;
  margin-right: 35px;
}

.kids-mg-link {
  width: 100px;
  border: var(--primary) solid 1px;
  border-radius: 10px;
}

.kids-mg-link:hover {
  cursor: pointer;
  border: var(--primary) solid 2px;
}

.kids-virtualstore-link {
  height: 48px;
  padding: 10px;
}

.kidsmagazine-virtual {
  display: flex;
  margin: 40px 0px 0 0;
}

@media (max-width:449px){
  .kidsmag-container {
    flex-direction: column-reverse;
    margin: -28px 0 45px 0;
  }

  .kidsmag-text{
    margin: 54px 0px 50px 0px;
  }

  .kidsmag-text h1{
    margin: 0 0px 0 20px;
  }

  .kidsmag-text p {
    margin: 10px 2px 55px 16px;
  }

  .kidsmag-img{
    width: 264px;
    height: auto;
    margin: 0;
  }

  /* .kidsmagazine-virtual{
    margin: 40px 0px 0 14px;
  } */

  .kids-virtualstore-link{
    height: 33px;
    padding: 5px;
  }
}

@media (min-width: 450px) and (max-width:599px){
  .kidsmag-container {
    flex-direction: column-reverse;
    margin: -28px 0 45px 0;
  }

  .kidsmag-img{
    width: 300px;
    height: auto;
    margin: -19px 0 0 0;
  }

  .kidsmag-text{
    margin: 54px 0 50px 0;
  }

  .kidsmag-text h1{
    margin: 0 0px 0 20px;
  }

  .kidsmag-text p {
    margin: 10px 76px 55px 98px;
  }

  .kidsmag-img{
    margin-right: 0;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .kidsmag-container {
    flex-direction: column-reverse;
    margin: -28px 0 45px 0;
  }

  .kidsmag-text p {
    margin: 10px 146px 55px 144px;
  }

  .kidsmag-img {
    width: 300px;
    height: auto;
  }

  .kidsmag-text img {
    margin: -19px 5px 0 0;
  }

  .kidsmag-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 54px 0 50px 0;
  }

  /* .kidsmagazine-virtual {
    margin: 53px 0 -25px 0;
  } */

  .kids-virtualstore-link {
    height: 28px;
    margin-top: 0;
    padding: 0;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .kidsmag-container {
    margin: -15px -1px -4px -2px;
  }

  .kidsmag-text{
    margin:54px 36px 50px 36px;
  }

  .kidsmag-text p {
    margin: 10px 50px 55px 42px;
  }

  .kidsmag-img {
    height: 330px;
    margin: 0 -22px 0 10px;
  }


}

@media (min-width: 1300px){

  .kidsmag-text {
    font-size: 18px;
  }

  .kidsmag-text p{
    font-size: 18px;
  }

  .kidsmag-img{
    height: 550px;
  }

  .kids-virtualstore-link{
    height: 60px;
  }

  .kidsmagazine-virtual{
    margin: 112px 0px 0 0;
  }

  .kids-mg-link{
    width: 140px;
  }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.avatar-selection-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 50px;
    width: 80%;
    max-width: 500px;
    position: relative;
    text-align: center;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.title {
    margin-bottom: 20px;
    color:#FF6632 !important;
}

.avatars-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin:auto;
    //background-color:red;
    margin-left:1vw;
    margin-bottom:30px;
}

.avatar-item {
    cursor: pointer;
}

.avatar-img {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.avatar-img:hover {
    transform: scale(1.1);
}

.teacher-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.profile-container {
    position: relative;
    display: inline-block;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #ccc;
    object-fit: cover;
}

.loading-placeholder {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
    border-radius: 50%;
    border: 4px solid #ccc;
}

.profile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.profile-container:hover .profile-overlay {
    opacity: 1;
}

.profile-overlay img {
    width: 50px;
    height: 50px;
}

.item1 {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
}

.item2 {
  background-color: black;
  color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.item3 {
  background: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
}

.item4 {
  color: var(--primary);
  display: flex;
  justify-content: center;
}

.item5 {
  background-color: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
}

.item6 {
  color: white;
  position: relative;
  background-image: url("../images/bedtime/Beyond_Blue_background_1288x570.png");
  background-size: cover;
  background-repeat: no-repeat, repeat;
  display: flex;
  justify-content: center;
}

.item7 {
  background-color: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.item8 {
  background-color: var(--secondary);
  color: var(--primary);
  display: flex;
  justify-content: center;
}

.item9 {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item10 {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
}

.home-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .item2 {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

.alice-carousel__dots-item:not(.__custom).__active{
  background-color: var(--primary);
  opacity: 100%;
  }

  .alice-carousel__dots-item:not(.__custom){
    background-color: var(--primary);
    opacity: 50%;
  }

  .alice-carousel__dots-item:not(.__custom):hover{
    background-color: var(--primary);
  }

  .alice-carousel__dots{
    margin: 0px 3px 29px;
  }

.mag-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 38px 0 38px 0;
  width: 100vw;
}

.mag-text {
  text-align: center;
  margin: 54px 29px 50px 10px;
}

.mag-text p {
  margin: 10px 0 55px 0;
  font-size: 14px;
}

.mg-link {
  width: 100px;
  border: white solid 1px;
  border-radius: 10px;
}

.mg-link:hover {
  border: rgb(246, 196, 60) solid 2px;
  cursor: pointer;
}

.mag-img {
  width: auto;
  height: 400px;
}

.magazine-virtual {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.virtualstore-link {
  height: 48px;
  padding: 10px;
}

@media (max-width:449px){
  .mag-container {
    flex-direction: column;
    margin: -18px 0px 56px 0px;
  }

  .mag-img {
    width: 264px;
    height: auto;
    margin: -19px 0 0 0;
  }

  .virtualstore-link{
    height: 33px;
    padding: 5px;
  }

  .mag-text{
    margin: 54px 18px 50px 20px;
  }
}

@media (min-width: 450px) and (max-width:599px){
  .mag-container {
    flex-direction: column;
    margin: -23px 0 38px 0;
  }

  .mag-img {
    width: 300px;
    height: auto;
    margin: -19px 0 0 0;
  }

  .mag-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 54px 0px 50px 0px;
  }

  .magazine-virtual {
    display: flex;
    margin: 53px 0 -25px 0;
  }

  .virtualstore-link {
    padding: 0;
    height: 28px;
    margin: 10px 5px 25px 0;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .mag-container {
    flex-direction: column;
    margin: -23px 0 38px 0;
  }

  .mag-img {
    width: 300px;
    height: auto;
    margin: -19px 0 0 0;
  }

  .mag-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 54px 0px 50px 0px;
  }

  .magazine-virtual {
    display: flex;
    margin: 53px 0 -25px 0;
  }

  .virtualstore-link {
    padding: 0;
    height: 28px;
    margin: 10px 5px 25px 0;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .mag-container {
    margin: 9px 0 0 0;
  }

  .mag-img {
    height: 330px;
  }
}

@media (min-width: 1300px){

  .mag-text {
    font-size: 18px;
  }

  .mag-text p{
    font-size: 18px;
  }

  .mag-img{
    height: 550px;
  }

  .virtualstore-link{
    height: 60px;
  }

  .magazine-virtual{
    margin: 112px 0px 0 0;
  }

  .mg-link{
    width: 140px;
  }
}

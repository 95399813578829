.header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  width: 100vw;
}
.header-container video{
width: 600px;
margin: 10px 0px 0px 0px;
}
.checkpoint-logo {
  width: auto;
  height: 100px;
}

.statement {
  margin: 2px 57px 19px 54px;
  font-size: 16px;
  color: white;
  text-align: center;
}

.portals {
  display: flex;
  width: 95vw;
}

.portals .alice-carousel__dots {
  display: none;
}

@media (min-width: 280px) and (max-width: 449px) {
  .header-container video{
  width: 270px;
  margin: -8px 0px -28px 0px;
 }
  /* .checkpoint-logo {
    height: 42px;
  } */

  .statement {
    font-size: 12px;
    margin: 10px 51px 19px 54px;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
 .header-container video{
  width: 300px;
  margin: -34px 0px -33px 0px;
 }
 
  /* .checkpoint-logo {
    height: 67px;
  } */

  .statement {
    font-size: 14px;
    margin: 10px 130px 19px 120px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {

  .header-container video{
    margin: 5px 0px 0px 0px;
  }
  .checkpoint-logo {
    height: 95px;
  }
}

@media (min-width: 1300px) {
  .checkpoint-logo {
    height: 120px;
  }

  .statement {
    font-size: 16px;
  }
}

@media (min-width: 900px) and (max-width: 1299px) {
  .portals .alice-carousel__dots {
    display: none;
  }

  .portals{
    margin: 10px 0 10px 0;
  }

  .statement{
    font-size: 16px;
  }

  
}

@media (min-width: 1300px){
  .header-container video{
    height: 320px;
    margin: -85px 0px -80px 0px;
  }
  .portals .alice-carousel__dots {
    display: none;
  }
 
}



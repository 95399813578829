


.kids-slide {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  //background-color:red;
  width:100vw;
  margin-top:4rem;
 
}

.kids-slide .ks-kidslogo {
  height: 100px;
  width: 400px;
}
.kids-slide-img {
  height: 300px;
  margin: 20px;
}

.kids-slide-cnt {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 449px){
    .kids-slide .ks-kidslogo{
        height: 60px;
        width: 225px;
    }
}
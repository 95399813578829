.kidsmain-cnt{
display: flex;
justify-content: center;
flex-direction: column;
min-height: 100vh;
}

.kids-nvb{
background-color: black;
}

.kids-content{
    flex: 1; /* Makes the content take up remaining vertical space */
    z-index: 0;
}

.kids-content img {
    height: 200px;
    margin: 20px;
}

.kids-footer{
    background-color: black;
    flex-shrink: 0;
}

@media screen and (max-width: 768px){
.kids-nvb{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 30px 0 20px;
}
}
.submissions-cnt {
  margin: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.submissions-cnt h1 {
  font-size: 30px;
  text-align: center;
  color: var(--primary);
  margin: 20px;
}

.submissions-header {
  text-align: justify;
  padding: 0 100px;
}

.submissions-header p {
  padding: 10px 100px;
}

.submissions-form {
  margin: 20px;
}

.submissions-form h2 {
  font-size: 18px;
  margin: 10px 0;
}

.submissions-form-ch,
.submissions-form-prt {
  display: flex;
  justify-content: space-between;
}

.submission-form-checks {
  margin-bottom: 30px;
}

.submission-form-check-row {
  display: flex;
}

.submissions-form-ch input,
.submissions-form-prt input,
.submissions-form-ch select {
  background-color: #d9d9d9;
  border: none;
  border-radius: 10px;
  padding: 2px 5px;
  margin: 5px 20px 20px 0px;
  width: 100%;
  height: 25px;
}

.submissions-form-label {
  margin-right: 10px;
}

.submission-form-check-text {
  margin: 10px;
}

.submission-form-btn {
  background-color: var(--primary);
  border: none;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
  width: 100px;
  font-size: 14px;
}

.submission-form-btn:hover {
  cursor: pointer;
  font-weight: bold;
  background-color: rgb(255, 136, 0);
}

.submission-form-btn-cnt {
  display: flex;
  justify-content: center;
  font-family: "urw-din", sans-serif;
  margin-top: 20px;
}

.submission-form-dropzone {
  width: 90vw;
  background-color: #d9d9d9;
  border-radius: 10px;
}

.submission-form-dropzone-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.submission-dropzone-info-text {
  color: grey;
  font-size: 14px;
  margin: 5px 0 20px 0;
}

.submission-cloud {
  color: grey;
}

.submission-form-capacity-warn {
  font-size: 12px;
}

.submission-dropzone-removefile {
  color: var(--primary);
}

.submission-dropzone-remove-btn {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 45%;
  right: 10px;
}

.submission-dropzone-files {
  list-style-type: none;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.submission-dropzone-previewimg {
  display: flex;
  align-items: center;
  background-color: #efecec;
  border: grey 1px solid;
  border-radius: 10px;
  margin: 10px;
  position: relative;
  padding: 20px;
}

.submission-dropzone-previewimg img {
  height: 50px;
  width: 50px;
}

.submission-dropzone-img-name {
  padding: 0 20px;
}

/* modal CSS */
.submission-modal{
position: fixed;
z-index: 1;
border: var(--primary) 1px solid;
background-color: white;
border-radius: 10px;
display: flex;
justify-content: center;
text-align: center;
padding: 40px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.submission-modal-xmark{
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--primary);
}

.submission-modal-content-text h2{
font-size: 24px;
color: var(--primary);
}

.submission-modal-content-text p {
    margin: 10px;
}

.submission-modal-backl-btn{
    background-color: var(--primary);
    border-radius: 10px;
    border: none;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    width: 100px;
    margin: 20px 0 15px 0;
    font-family: "urw-din", sans-serif;
}

.submission-modal-backl-btn:hover{
    cursor: pointer;
    font-weight: bold;
    background-color: rgb(255, 136, 0);
}

.submission-modal-content-text img{
  border: var(--primary) 1px solid;
  border-radius: 10px;
  height: 150px;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

.loading-animation {
  animation: flash 3s linear infinite;
  background-color: rgb(213, 103, 9);
  font-weight: bold;
}

@media (max-width: 400px){
.submission-modal{
  width: 270px;
}
}

@media (max-width: 768px){
  .submissions-header{
    padding: 0;
  }
  .submissions-header p{
    padding: 0 20px;
  }

  .submissions-form-ch,
.submissions-form-prt{
 
  flex-direction: column;
  align-items: stretch;
}

.submissions-form h2{
  text-align: center;
}

.submission-dropzone-previewimg {
padding: 0;
}

.submission-dropzone-remove-btn{
  top: 5%;
    right: 3%;
}
}
.kids-navbar-cnt2 {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  //background-color: green;
  
}

.home-logo2 {
  //isplay: flex;
  align-items: center;
  //background-color:yellow;
  left: 20px;
  top: 0;
}

.home-logo2 img {
  height: 60px;
}

.kids-nav-menu {
  display: flex;
  margin-left:23%;
  justify-content: center;
  //flex-grow: 1;
  //background-color:green;
  width:50%;
}

.kids-nav-menu.hide-mobile {
  display: none;
}

.kids-nav-btn {
  border: none;
  background-color: transparent;
  font-family: "urw-din";
  margin: auto;
  display:flex;
  justify-self:center;
}

.kids-nav-btn a:hover {
  cursor: pointer;
  background-color: var(--primary);
}

.kids-nav-btn a {
  text-decoration: none;
  color: white;
  font-family: "urw-din";
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  
}

.kids-nvb-item {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 10px;
  font-family: "urw-din";
  margin:0px 50px 0 40px;
  

}

.kids-nvb-item:hover {
  cursor: pointer;
  background-color: var(--primary);
}

.kids-dpd-cnt {
  display: none; /* needs to be "none" otherwise a block of pixels shows */
  position: absolute;
  background-color: black;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
.kids-nav-item-profile-dropdown-container{
  color:white;
  font-family: "urw-din";
  //background-color:yellow;
  display:fixed;
  display:flex;
  margin:auto;
}

.nav-item-profile-dropdown-container {
  position: absolute;
  right: -8vw;
  display: flex;
  align-items: center;
}

.profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-icon {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.profile-dropdown-content-kids{
  display: none;
  position: absolute;
  flex-direction: column;
  
  background-color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;

  text-align: center;
  font-family: "urw-din", sans-serif;
  font-size:12px;
  margin-top: 60px;
}

.profile-dropdown-content-kids a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-family: "urw-din", sans-serif;
  cursor: pointer;
}

.profile-dropdown-content-kids a:hover {
  color: var(--primary);
}

.profile-dropdown.show .profile-dropdown-content-kids {
  display: flex;
}



.kids-dpd-cnt {
  display: none; /* needs to be "none" otherwise a block of pixels shows */
  position: absolute;
  background-color: black;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}

.kids-dpd-cnt button {
  border: none;
  background-color: transparent;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
}

.kids-dpd-cnt a:hover {
  color: var(--primary);
  cursor: pointer;
}

.kids-dpd-cnt a {
  text-decoration: none;
  color: white;
  padding: 0 10px;
  background-color: transparent;
}

.kids-nvb-dpd.show .kids-dpd-cnt {
  display: block;
  overflow: hidden;
}

.kids-nvb-dpd {
  position: relative;
}

.kids-icon-menu {
  display: none;
}

@media screen and (max-width: 1000px){
  .kids-nav-menu {
    display: flex;
    justify-content: center;
    width:600px;
    margin-left:0;
  }

  .kids-nav-item-profile-dropdown-container{
    display:hide;
  }
}



@media screen and (max-width: 768px) {
  .kids-navbar-cnt2 {
    flex-direction: column;
    align-items: flex-start;
  }

  .kids-nav-menu {
    flex-direction: column;
    align-items: center;
    width:100%;
  }

  .home-logo2 {
    position: absolute;
    left: 20px;
    top: 0;
  }

  .kids-nav-btn, .kids-nvb-item {
    margin: 10px 30px;
  }

  .kids-nvb-dpd {
    position: relative;
    margin-top: 5px;
  }

  .kids-dpd-cnt {
    position: static;
  }

  .kids-dpd-cnt div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  

  .kids-icon-menu {
    display: flex;
    position: absolute;
    top: 20px;
    right: 10px;
    margin-top: 5px;
    //background-color:red;
  }

  .kids-dropdown-icon {
    color: var(--primary);
    transition: transform 0.3s ease-in-out;
    margin-right:20px;
  }

  .kids-dropdown-icon.open {
    transform: rotate(180deg);
  }

  .kids-nav-btn a {
    font-size: 12px;
  }

  .kids-nvb-item {
    font-size: 12px;
  }

  .kids-nav-item-profile-dropdown-container{
    display:none;
  }
  .kids-nav-item-profile-dropdown-container2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color:white;
    font-size: 12px;
  }
  .kids-dpd-cnt a {
    font-family: "urw-din";
  }

  .profile-dropdown-content-kids2 {
    position: static;
    align-items: center;
    //margin-right:70px;
    margin-top:10px;
    width: auto;
    padding:none;
    display:flex;
    flex-direction:column;
    align-self:center;
    margin:auto;

  }

  .profile-dropdown-content-kids2 a {
    text-decoration: none;
    color: white;
    font-family: "urw-din";
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    margin: 10px 30px;
    
  }

  .profile-dropdown-content-kids2 a:hover{
    cursor: pointer;
    background-color: var(--primary);
  }
}

.friends-cnt{
margin: 20px;
}

.friends-header{
    text-align: left;
    padding: 0 100px;
}

.friends-header h1{
    font-size: 30px;
    text-align: center;
    color: var(--primary);
    margin: 20px;
}

.friends-text p{
    padding: 20px 200px;
}

.friends-logos-cnt{
    display: flex;
    justify-content: space-evenly;
    margin: 37px 10px 44px 10px;
    flex-wrap: wrap;
}

.friends-logos-cnt .friends-logo {
    height: 200px;
}
/* 
.friends-logos-cnt .friends-un{
height: 120px;
margin: -10px 0 40px 0;
}

.friends-logos-cnt .friends-brunel{
height: 70px;
margin: 15px 0 40px 0;
}

.friends-logos-cnt .friends-sega{
height: 70px;
margin: 15px 5px 40px 5px;
}

.friends-logos-cnt .friends-taminggaming{
height: 100px;
margin: 0px 20px 40px 20px;
}

.friends-logos-cnt .friends-ftl{
height: 100px;
margin: 20px 30px 20px 30px;
}

.friends-logos-cnt .friends-vedex{
height: 70px;
padding: 5px;
background-color: black;
}

.friends-logos-cnt .friends-mettle{
height: 100px;
margin: 10px 20px;

}

.friends-logos-cnt .friends-cisco{
height: 100px;
margin: 20px 20px;
}

.friends-logos-cnt .friends-digilearning{
height: 70px;
margin: 40px 20px 0 20px;
}

.friends-logos-cnt .friends-esa{
height: 150px;
margin: 0px 0 0 0;
}

.friends-logos-cnt .friends-fuze{
height: 70px;
}

.friends-logos-cnt .friends-nlt{
height: 100px;
margin: 20px 0 0 0;
}

.friends-logos-cnt .friends-nso{
height: 150px;
margin: -10px 0 0 0;
}

.friends-logos-cnt .friends-teamgb{
height: 120px;
margin: -20px 0 0 0;
} */

@media (max-width: 768px){
    .friends-text p {
        padding: 20px 0px;
        text-align: justify;
    }
}
.sponsors-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100vw;
  margin-top: 20px; 
    
}

.sponsors-title{
  text-align: center;
  font-size: 12px;
}

.sponsors-display{
  display: flex;
    justify-content: space-evenly;
    margin: 37px 10px 44px 10px;
    flex-wrap: wrap;
  
}

.sponsors-logo {
  height: 120px;
  margin: 0;
  filter: grayscale(100%);
}

.sponsors-logo img{
  margin: 0;
}

@media (max-width: 449px) {
  .sponsors-logo{
    height: 64px;
    
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .sponsors-display{
    margin: 37px 4px 44px -6px;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .sponsors-display{
    margin: 37px 19px 44px 8px;
  }

  .sponsors-logo{
    height: 108px;
    
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .sponsors-display{
    margin: 37px 91px 44px 79px;
  }
}

@media(min-width:1300px) {
  .sponsors-title{
    font-size: 18px;
  }
}


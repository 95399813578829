:root {
  --primary: #FF6632;
  --secondary: #E0E1E0;
  --terciary: #cac7c75c;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "urw-din", sans-serif;
}

.overlay.hidden {
  display: none;
}


.popup {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.close-btn {
  position: absolute;
  top: 6px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #333;
  font-family: "urw-din", sans-serif;
}

.register-sign-container {
  background-color: white;
  font-family: "urw-din", sans-serif;
  width: 505px;
  height: 260px;
  border-radius: 25px;
  display: grid;
  justify-items: center;
  align-items: center;
  box-shadow: 5px 5px 5px black;
  text-align: center;
  padding: 20px;
}


.register-sign-container h4{
  margin-top:30px;
  color:var(--primary);;
  font-family: "urw-din", sans-serif;
  font-size:18px

}

.register-sign-container span{
  color:var(--primary);
  font-family: "urw-din", sans-serif;
  font-size:15px;
}
.underline{
  text-decoration:underline;
}


.registration-button {
  background-color: var(--primary);;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px;
  margin: 10px;
  width: 200px;
  height:40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "urw-din", sans-serif;
  font-size:15px;
  
}

.registration-button:hover {
  background-color: #e05529;;
}

.register-sign-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.sign-in-link {
  margin-top: 10px;
  color: #007bff;
  font-size: 16px;
}

.sign-in-link a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.sign-in-link a:hover {
  color: #0056b3;
}

.error-message {
  color: #e74c3c;
  margin-top: 20px;
  font-size: 16px;
}


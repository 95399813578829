/* Define color variables */
:root {
    --primary: #FF6632;
    --secondary: #E0E1E0;
    --terciary: #cac7c75c;
}

/* Popup styles */
.fixed {
    position: fixed;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.z-50 {
    z-index: 50;
}

.bg-black {
    background-color: black;
}

.opacity-50 {
    opacity: 0.5;
}

.absolute {
    position: absolute;
}

.bg-orange-500 {
    background-color: var(--primary);
}

.text-white {
    color: #ffffff;
}

.p-4 {
    padding: 1rem;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.relative {
    position: relative;
}

.z-10 {
    z-index: 10;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.font-bold {
    font-weight: 700;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-4 {
    margin-top: 1rem;
}

.bg-white {
    background-color: #ffffff;
}

.text-orange-500 {
    color: var(--primary);
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.multiverse-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin: 20px 0 20px 0;
}

.multiverse-text {
  text-align: center;
}

.multiverse-text p {
  font-size: 14px;
  margin: 10px 263px 0 236px;
}

.multiverse-img {
  width: 500px;
  height: auto;
  border-radius: 50px;
  padding: 20px;
}

.multiverse-display {
  display: flex;
  justify-content: center;
  margin: 39px 10px 33px 10px;
}

.oculus-link a {
  text-align: center;
  text-decoration: none;
  color: white;
}

.oculus-link p {
  border: white solid 1px;
  border-radius: 5px;
  width: 100px;
  padding: 5px;
}

.oculus-link {
  display: flex;
  justify-content: center;
  padding: 0 0 30px 0;
}

@media (max-width: 449px) {
  .multiverse-text p {
    margin: 10px 8px 0 5px;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .multiverse-text p {
    margin: 10px 55px 0 51px;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .multiverse-text p {
    margin: 10px 130px 0 129px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .multiverse-text p {
    margin: 10px 217px 0 209px;
  }
}

@media (min-width: 1300px){
.multiverse-text, .multiverse-text p{
  font-size: 18px;
}
}

/* .multiverse-display .alice-carousel__dots-item:not(.__custom).__active {
  background-color: white;
  opacity: 100%;
}

.multiverse-display .alice-carousel__dots-item:not(.__custom) {
  background-color: white;
  opacity: 50%;
}

.multiverse-display .alice-carousel__dots-item:not(.__custom):hover {
  background-color: white;
}

.multiverse-display .alice-carousel__dots {
  margin: 30px 3px 29px;
} */

.multiverse-display .alice-carousel__dots{
  display: none;
}
.column1 {
  grid-area: "column1";
}

.column2 {
  grid-area: "column2";
  display: grid;
}

.column3 {
  grid-area: "column3";
  display: grid;
}

.column4 {
  grid-area: "column4";
  margin-top: -5px;
}

.column5 {
  grid-area: "column5";
 
}

.footer-container {
  display: grid;
  grid-template-areas:
    "column1 column1 column1 column1"
    "column2 column2 column2 column2"
    "column3 column3 column3 column3"
    "column4 column4 column4 column4"
    "column5 column5 column5 column5";
  margin: 20px 0px 20px 10px;
  width: 90vw;
}

.footer-logo {
  width: auto;
  height: 40px;
}

.social-media-icon {
  margin-left: 65px;
}

.footer-link {
  text-decoration: none;
  color: #e55127;
  font-size: 12px;
  margin-bottom: 5px;
}

.footer-contacts-title,
.footer-contacts-body {
  font-size: 12px;
  padding-left: 5px;
  margin-bottom: 5px;
}

.footer-contacts-title {
  color: var(--primary);
  margin-bottom: 5px;
}

.footer-contacts-body{
  color: white;
}

.footer-contacts-line {
  display: flex;
  align-items: center;
}

.copyright p {
  font-size: 8px;
  margin-top: 120px;
  color: white;
}

.footer-contacts-line{
  margin-top: 20px;
}

@media (max-width: 320px){
  .footer-contacts-title, .footer-contacts-body{
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .footer-container {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  .column1,
  .column2,
  .column3{
    margin: 0 0 20px 0;
  }

  .column4 {
    margin: 18px 0 20px 0;
  }

  

  .social-media-icon {
    margin-left: 0;
    margin-top: 10px;
  }

  .footer-logo {
    margin-bottom: 10px;
  }

  .footer-contacts-line {
    align-items: flex-start;
  }

  .copyright {
    margin: -62px 0 0 0;
  }

  .footer-link {
    margin: 10px 0 0 0;
  }

  .footer-contacts-line {
    margin: 10px;
  }
}

@media(min-width: 1300px){
  .footer-link{
    font-size: 18px;
  }

  .copyright p{
    font-size: 12px;
  }

  .footer-contacts-title, .footer-contacts-body{
    font-size: 16px;
  }
}

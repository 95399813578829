.create-modal-cnt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.tl-cnt{
  width:100vw;  
}
.create-modal-img-cnt {
  display: flex;
  align-items: center;
}

.create-modal-cnt img {
  width: 400px;
  margin: 120px 0;
}

.create-modal-btn-xmark {
  border: none;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
}

.create-modal-btn {
  margin: 0 200px 10px 200px;
  background-color: var(--primary);
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  color: white;
}


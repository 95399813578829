.shop-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
}

.shop-text {
  text-align: center;
  margin: 30px 0 10px 0;
  font-size: 14px;
}

.shop-text p {
  margin: 10px 10px 0 10px;
}

.shop-display {
  display: flex;
  justify-content: center;
  margin: 39px 40px 33px 40px;
}
.shop-link a {
  text-align: center;
  text-decoration: none;
  color: var(--primary);
}

.shop-link p {
  border: var(--primary) solid 1px;
  border-radius: 5px;
  width: 100px;
  padding: 5px;
}

.shop-link {
  display: flex;
  justify-content: center;
  padding: 0 0 30px 0;
}

@media (max-width: 449px) {
  .shop-text p {
    margin: 10px 78px 0 80px;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .shop-text p {
    margin: 10px 113px 0 115px;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 900px) {
}

@media (min-width: 1300px){
  .shop-text{
    font-size: 18px;
  }
  }

.shop-display .alice-carousel__dots {
  /* margin: 30px 3px 29px; */
  display: none;
}

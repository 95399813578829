.shop-icon-container {
  display: flex;
  justify-content: center;
  margin: 0;
}

.product {
  width: 200px;
  height: 200px;
}

@media (max-width: 449px) {
}

@media (min-width: 450px) and (max-width: 599px) {
}

@media (min-width: 600px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 900px) {
}

.portal-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.portal img {
  width: 100px;
  height: 100px;
}

@media (min-width: 1300px){
.portal img{
  height: 150px;
  width: 150px;
}
}




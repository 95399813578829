:root {
    --primary: #FF6632;
    --secondary: #E0E1E0;
    --background: #f5f5f5;
    --input-border: #ddd;
    --input-focus: #FF6632;
}

body {
    font-family: "urw-din", sans-serif;
    margin: 0;
    background-color: var(--background);
}

.create-class-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.create-class-form-wrapper {
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 500px;
    width: 100%;
}

.create-class-close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.create-class-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create-class-title-container {
    margin-bottom: 1.5rem;
}

.create-class-title-container h2 {
    font-size: 1.5rem;
    color: var(--primary);
    text-align: center;
    font-family: "urw-din", sans-serif;
}

.create-class-error-message, .create-class-success-message {
    color: var(--primary);
    margin-bottom: 1rem;
    text-align: center;
    font-family: "urw-din", sans-serif;
}

.create-class-row.create-class-clearfix {
    width: 100%;
}

.create-class-input-field {
    margin-bottom: 1.5rem;
    width: 100%;
    color: var(--primary);
}

.create-class-input-field input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-family: "urw-din", sans-serif;
}

.create-class-input-field input:focus {
    border-color: var(--primary);
    outline: none;
}

.create-class-flex.create-class-justify-end {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.create-class-button {
    background: var(--primary);
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    transition: background 0.3s ease;
    text-align: center;
    font-family: "urw-din", sans-serif;
}

.create-class-button:hover {
    background: #FF4500;
}

.create-class-dropdown {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
    margin-top: 1rem;
    font-family: "urw-din", sans-serif;
    background: #fff;
    color: #333;
}

.create-class-dropdown:focus {
    border-color: var(--primary);
    outline: none;
}

.create-class-dropdown:hover {
    border-color: var(--primary);
    outline: none;
}

.resources-list {
    margin-top: 1rem;
}

.resources-list h3 {
    font-size: 1.25rem;
    color: var(--primary);
}

.resources-list ul {
    list-style: none;
    padding: 0;
}

.resources-list li {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--input-border);
}

.confirmation-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.confirmation-popup-wrapper {
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 500px;
    width: 100%;
}

.confirmation-popup-close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.confirmation-popup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation-popup-title-container {
    margin-bottom: 1.5rem;
}

.confirmation-popup-title-container h2 {
    font-size: 1.5rem;
    color: var(--primary);
    text-align: center;
    font-family: "urw-din", sans-serif;
}

.confirmation-popup-message {
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
    font-family: "urw-din", sans-serif;
}

.confirmation-popup-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.confirmation-popup-button {
    background: var(--primary);
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    width: 48%;
    transition: background 0.3s ease;
    text-align: center;
    font-family: "urw-din", sans-serif;
}

.confirmation-popup-button:hover {
    background: #FF4500;
}


@media (max-width: 768px) {
    .create-class-form-wrapper{
        width:80%;
    }
    .confirmation-popup-wrapper {
        width:80%;

    }
}

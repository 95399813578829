.covers-cnt {
  margin: 0px;
}

.covers-cv {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  padding: 0px;
  overflow: hidden;
}

.covers-cv-video {
  margin: -43px;
}

.covers-btn-cnt {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.covers-btn {
  background-color: var(--primary);
  border: none;
  font-family: "urw-din", sans-serif;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  text-align: center;
  width: 170px;
  font-weight: bold;
}
